import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/ai/ai-banner.png";
import ContrastSection from "../../../components/ContrastSection";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Icons
import RiskAI from "../../../images/service-icons/ai/risk.svg";
import DecisionAI from "../../../images/service-icons/ai/decision.svg";
import PartnerAI from "../../../images/service-icons/ai/partner.svg";
import ExpertiseAI from "../../../images/service-icons/ai/expertise.svg";
import ExperienceAI from "../../../images/service-icons/ai/experience.svg";
import FastAI from "../../../images/service-icons/ai/fast.svg";
import TeamAI from "../../../images/service-icons/ai/team.svg";
import SecureAI from "../../../images/service-icons/ai/secure.svg";
import OrganisationAI from "../../../images/service-icons/ai/organisation.svg";
import DevelopmentAI from "../../../images/service-icons/ai/development.svg";

// Clients
import GigaClear from "../../../images/services/logo/gigaclear.png";
import Effi from "../../../images/services/logo/effi-logo.png";
import Pokitpal from "../../../images/services/logo/pokitpal.png";
import Possibl from "../../../images/home-page/logo/possibl-logo.png";
import Commercial from "../../../images/home-page/logo/commercial-logo.png";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial1 from "../../../components/ClientTestimonialRobbiePossibl";
import RobbieBrender from "../../../images/Clients/RobbieBrender.jpg";

// Casestudy
import caseStudyImg from "../../../images/services/ai/casestudy-img-ai.jpg";
import downloadFile from "../../../documents/Case-Study-AI-Lead-Distribution.pdf";

// Contact
import SvgIconsSet from "../../../components/SvgIconsSet";
import UserCard from "../../../components/UserCard";
import Chrishmal from "../../../images/Chrishmal.png";

const Fintech = () => {
  const servicesList = [
    {
      id: "1",
      title: "AI Strategy and Roadmap Consultation",
      textbold:
        "Want to outline your short-term and long-term AI strategy and ensure it is sustained?",
      textdetail:
        "Many organisations need support to decipher the advantages of AI as well as risks of AI, when they establish a value-adding AI roadmap for their business. iTelaSoft provides in-depth consultation and hand-holding in discovering one’s unique path of embracing AI. This exercise involves assessing the AI maturity of the organisation, uncovering opportunities for leveraging AI, and figuring out how to navigate through ethical, societal, technical, and security issues.",
    },
    {
      id: "2",
      title: "AI Enabled Smart Products",
      textbold: "Want to make your existing application or SaaS product smart?",
      textdetail:
        "If you have an already operating software product that provides value to your customers, iTelaSoft can engage in making them smarter by adding intelligent features. These include productivity enhancers, personalisation, predictions, AI assistants or AI chatbots, and insight-driven smart behaviours. iTelaSoft has a series of AI capability building blocks to accelerate the process of making products smarter.",
    },
    {
      id: "3",
      title: "AI Transformation of Operations",
      textbold:
        "Want to leverage AI to increase operational efficiencies and quality?",
      textdetail:
        "Organisations that have already gone through a digital transformation are using digital workflows and tools to manage their business operations. These systems can be supercharged by adding AI Models to intelligently enhance their behaviour. iTelaSoft has been engaging in AI-enabling such systems for years, using traditional Machine Learning techniques as well as newly emerging Generative AI.",
    },
    {
      id: "4",
      title: "Data Management & Insights",
      textbold:
        "Want to create useful insights and monetise data you already generate?",
      textdetail:
        "New-age businesses already possess heaps of data generated in their operations. These data can be often converted to valuable insights and decision assist tooling. iTelaSoft has been providing Data pipeline services, Analytics, Dashboards, and Self-Service BI tools for such organisations. With recent advancements in AI, iTelaSoft has been supporting businesses to uncover deeper insights from various data sources which were not possible previously.",
    },
    {
      id: "5",
      title: "MLOps, ModelOps and AI Testing",
      textbold:
        "Having trouble streamlining your machine learning and AI development process?",
      textdetail:
        "AI-based application development and AI model creation are becoming increasingly sophisticated. Also, it adds operational, governance, and sustainability issues if automation and best practices are not employed. iTelaSoft supports organisations to employ industry standard practices around MLOps, ModelsOps, and establish governance while maintaining cost efficiencies. Providing application testing related to AI-related use cases is one more service provided by iTelaSoft.",
    },
  ];
  const tabData = [
    {
      label: "Robbie Brender",
      text: "Possibl",
      image: RobbieBrender,
      content: <ClientTestimonial1 />,
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile]);

    const formData = { ...values };
    formData.Message = "CASE STUDY: 40% REDUCTION IN CUSTOMER RESPONSE TIME";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: ARTIFICIAL INTELLIGENCE";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page ai-service">
      <Seo
        keyword="AI chatbots, AI chat, Chatbot AI, Artificial Intelligence chat, AI chat online, Artificial chat, AI for businesses, AI strategy, AI process, AI products, AI enabled products, AI risks, Use of AI, Advantages of AI in business, AI implementation strategy"
        title="Data & AI Enablement"
        description="Innovation Partner - IoT, FinTech, Mobile Apps & Software Development, Cloud Engineering | iTelaSoft"
      />

      <IntroBanner
        title="Data &amp; AI Enablement"
        subtitle="Unearth deep data insights with innovative AI solutions"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="AI Transformation & Data Management"
          para="During the last three decades, businesses achieved a growth rate never seen in history by embracing Digital Transformation. Through that, organisations transformed from manually driven operations to automated operations. In the latter part of the last decade, this was further elevated using a vast amount of data being generated, by transforming businesses into ‘insight-driven’ organisations. Today we are again facing a historic shift in technology, through advancements in Artificial Intelligence. Progressive businesses are already taking ambitious steps for the ‘AI Transformation’ of their businesses to stay competitive and grow. iTelaSoft has well understood this need and provides a series of services for such businesses to leverage AI for business impact."
        />
      </div>

      <section>
        <Container className="mt-4">
          <div className="title title-1">
            Why should you pay attention to ‘Safe and Responsible AI’?
          </div>
          <div className="sub-title">
            Early applications of AI were limited to very specific tasks, or a
            smaller part in a much larger problem. With the recent development
            of advanced AI techniques, Artificial Intelligence is starting to
            play a role in more broader and generic use cases more and more.
            They are now directly involved with Customer Experience, Employee
            Experience, and some cases even in high impact decision making. In
            an environment like that, ‘Safe and Responsible AI’ is an important
            consideration in any organisation’s strategic AI roadmap, AI
            initiatives, as well as use of AI. For more information read
            {" "}
            <a
              className="blog-link"
              href="https://www.industry.gov.au/news/australian-governments-interim-response-safe-and-responsible-ai-consultation"
              rel="noreferrer noopener"
              aria-label="Australian-Government’s-interim-response-to-safe-and-responsible-AI."
              target="_blank"
            >
              Australian Government’s interim response to safe and responsible
              AI.
            </a>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="title title-1">
            Why is a Trusted Partner required for your AI Strategy?
          </div>
          <div className="sub-title">
            Choosing a partner with the correct background and capabilities is
            essential to managing diverse challenges in the space of AI.
          </div>

          <Row className="mt-2">
            <Col md={6} lg={10}>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={RiskAI} alt="Icon" />
                </div>
                <div className="text">
                  Using AI is not only dealing with technology! It has risks
                  associated with privacy, data accuracy, and ethics, where you
                  may need guidance.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={DecisionAI} alt="Icon" />
                </div>
                <div className="text">
                  AI technology space is rapidly evolving, and still going
                  through a phase of gaining maturity. Well-informed
                  decision-making is essential in selecting technologies.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={PartnerAI} alt="Icon" />
                </div>
                <div className="text">
                  As of today, you might not have in-house AI capabilities to
                  drive your vision. You may need a partner who is ready to
                  immediately contribute while nurturing the capabilities of
                  your team.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={ExpertiseAI} alt="Icon" />
                </div>
                <div className="text">
                  You need results from your AI implementation Strategy without
                  waiting for a long time! Assessing the feasibility of AI use
                  cases, building proofs-of-concept, selecting correct
                  technologies, tooling, and making quick course corrections are
                  part of this journey. Achieving results in the short term to
                  prove your long-term success just becomes easier with the
                  support of an experienced partner.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-2">
                  Our comprehensive AI Transformation Services
                </div>
                <div className="sub-title">
                  iTelaSoft provides a series of services for AI Enablement and
                  drives AI Strategy for a wide variety of organisations. They
                  vary from building Smart AI Products to outlining the AI
                  Roadmap for a business.
                </div>
              </div>
              <div className="mt-4">
                {servicesList.map((service) => (
                  <div className="wrapper-service" key={service.id}>
                    <div className="service-details">
                      <div className="service-title">{service.title}</div>
                      <div className="service-content ml-2">
                        <div className="service-breif pb-1">
                          {service.textbold}
                        </div>
                        <div className="service-description">
                          {service.textdetail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div className="wrap-case-study mt-3">
          <Container>
            <div className="row py-6">
              <div className="col-12">
                <div className="career">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-6 flex-column">
                        <div>
                          <h5 className="title-5 mb-0">
                            Case Study: 40% reduction in customer response time
                          </h5>
                          <h3 className="title-3 mt-0">
                            DISCOVER THE POWER OF AI IN MORTGAGE BROKING
                          </h3>
                          <p>
                            Learn how our AI solutions helped mortgage brokers
                            achieve:
                          </p>
                          <ul>
                            <li>40% faster customer response times</li>
                            <li>30% increase in lead management efficiency</li>
                          </ul>
                          <p>
                            Unlock applicationable insights and see how AI can
                            transform your business operations.
                          </p>
                        </div>
                        <div className="mt-2 mb-3">
                          <p>
                            <b>Download the Case Study Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column justify-content-md-between mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="col-lg col-md pr-1 col-sm-7 col-12 p-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Study
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />

                          <p className="small-text">
                            By downloading this case study, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img
                          alt="product innovation"
                          className="img-fluid"
                          src={caseStudyImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section>
        <Container>
          <div>
            <div className="title title-1 mb-2">Why iTelaSoft?</div>
            <div className="sub-title">
              A multi-disciplinary team with ACS-certified professionals for
              defining and supporting AI Strategy for our customers.
            </div>
          </div>
          <Row className="mt-4">
            <Col md={6} lg={6}>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={ExperienceAI} alt="Icon" />
                </div>
                <div className="text">
                  Experience in traditional Machine Learning techniques as well
                  as modern Generative AI methods.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={TeamAI} alt="Icon" />
                </div>
                <div className="text">
                  A multi-disciplinary team of advisors for defining and
                  supporting the AI Strategy of our customers.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={OrganisationAI} alt="Icon" />
                </div>
                <div className="text">
                  Out-of-the-box processes and tooling for organisation, team,
                  and AI project assessments.
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={FastAI} alt="Icon" />
                </div>
                <div className="text">
                  Readily available AI starter kits for faster bootstrapping
                  common AI applications.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={SecureAI} alt="Icon" />
                </div>
                <div className="text">
                  Strong and secure data handling policies and practices working
                  with our customer’s data.
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={DevelopmentAI} alt="Icon" />
                </div>
                <div className="text">
                  End-to-end product development capability which is eventually
                  necessary for building real-world AI applications.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
              <div id="who-depend-on-us">
                {" "}
                <div className="title-1 mb-2">Who depends on us? </div>
                <div className="section-our-partners-logo-set">
                  <a
                    href="https://gigaclear.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={GigaClear} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://effi.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Effi} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://www.pokitpal.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Pokitpal} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://www.possibl.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Possibl} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://www.combank.lk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Commercial} alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="client-testimonial-section mt-4 mb-4">
        <Container>
          <div className="tab-header-ai">
            <h3 className="title-1">What our client says</h3>
          </div>
          <TabcmpSet tabData={tabData} tabNum={1} />
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Chrishmal Fernando"
              >
                <UserCard
                  name="Chrishmal Fernando"
                  detail="AI Engineering Manager"
                  image={Chrishmal}
                  link="https://lk.linkedin.com/in/chrishmal-fernando-36289885"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Fintech;
